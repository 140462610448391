.state_input {
    color: black;
    font-weight: normal;
  }
  // button:disabled{
  //     height: 30px;
  //     margin-top: 18px;
  //     margin-left: 4px;
  //     margin-right: 4px;
  // }
  // button{
  //     height: 30px;
  //     margin-top: 18px;
  //     margin-left: 4px;
  //     margin-right: 4px;
  // }
  table {
    table-layout: fixed;
    width: 100%;
  }
  select {
    margin-bottom: 2px;
    color: #333;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    font-size: 1.5rem;
    display: flex;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
    border-radius: 8px;
  }
  .page_text {
    margin-top: 22px;
    margin-left: 8px;
    margin-right: 8px;
  }