@media (max-width: 1570px) {
  .navbar-header {
    float: none;
  }
  .navbar-default {
    display: flex !important;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    display: block !important;
    float: none !important;
    margin-top: 7.5px;
    height: auto !important;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    color: #fff !important;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent !important;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none !important;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    color: #fff !important;
  }

  .navbar-default .navbar-nav .dropdown.open > a:hover,
  .navbar-default .navbar-nav .dropdown.open > a:focus {
    background-color: #024272 !important;
    color: #fff !important;
    border: 0;
  }

  .navbar-default .navbar-nav .dropdown.open > ul > li > a:hover,
  .navbar-default .navbar-nav .dropdown.open > ul > li > a:focus {
    background-color: #024272 !important;
    color: #fff !important;
    border: 0;
  }

  .navbar-default .navbar-nav .dropdown .open > .dropdown-toggle,
  .navbar-default .navbar-nav li.dropdown.open i.fa.fa-angle-down {
    color: #fff;
  }
  .collapse.in {
    display: block !important;
  }
}

