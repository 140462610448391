.reason-box {
  list-style: none;
  min-height: 10rem;
  max-height: 30rem;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px;
}

.small-label {
  font-size: 1.1rem;
  text-overflow: clip;
  margin-bottom: 0;
  font-weight: 800;
}
