.version-history {
  border: 1px solid #000;
  font-family: arial;
  height: auto;
  width: auto;
  padding: 0;
}

.version-label {
  padding: none;
}

.active {
  text-decoration: none;
  color: #ffffff;
  background-color: #035fa4;
}
.version-history-ul {
  list-style: none;
  min-height: 10rem;
  max-height: 30rem;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px;
}

#buttons-list li:hover {
  cursor: default;
  text-decoration: none;
  color: #ffffff;
  background-color: #035fa4;
}
