.nav-group {
  width: 15vw;
}
.nav-btn {
  height: 5vh;
}
.progress-btn {
  width: 18vw;
}
.btn-default:hover {
  color: #fff;
  background-color: #035fa4;
  border-color: #035fa4;
}
.step-top-margin {
  margin-top: 6vh;
}
.input-top-margin {
  margin-top: 2vh;
}
.section-top-margin {
  margin-top: 10vh;
}

// Plan Type
.plan-type-disclaimer {
  margin-top: 15vh;
}

// Trust Info
.bottom-pos-parent {
  position: relative;
}
.bottom-pos-child {
  position: absolute;
  bottom: 0px;
}

// Plan Sponsor
.plan-sponsor-contact-row {
  margin-top: 10vh;
}

// Fiduciary Info
.fiduciary-contact-row {
  margin-top: 10vh;
}

// Investment Info
.dcio-top-margin {
  margin-top: 5vh;
}
.add-acct-top-margin {
  margin-top: 5vh;
}
//PA NAV
.pa-nav-container {
  display: block;
  width: 70%;
}

.pa-current-step {
  background-color: #035fa4;
  color: #fff;
  text-align: center;
  font-weight: 800;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0px;
}

.pa-default-step {
  background-color: #fff;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 800;
  margin: 0px;
}

.pa-nav-div {
  border: 1px solid #035fa4;
}

.pa-nav-div-current {
  background-color: #035fa4;
  border: 1px solid #035fa4;
}

.pa-nav-middle-child {
  border-left: 2px solid #035fa4;
  border-right: 2px solid #035fa4;
}

.pa-nav-top-child {
  border-left: 2px solid #035fa4;
  border-right: 2px solid #035fa4;
  border-top: 2px solid #035fa4;
}

.pa-nav-bottom-child {
  border-left: 2px solid #035fa4;
  border-right: 2px solid #035fa4;
  border-bottom: 2px solid #035fa4;
}
