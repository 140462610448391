table {
  border: 1px solid lightgray;
  width: 100%;
}

thead {
    background-color: #035fa4;
    color: white;
}

thead > tr > th > span > input {
  color: black !important;
}

tbody {
  border: 1px solid gray;

}

th {
  border: 1px solid gray;
  padding: 2px 4px;
}

td {
  border: 1px solid gray;
  padding-left: 5px;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.nav-btn {
  height: 7vh;
}

select {
  color: #035fa4;
}